'use strict';

// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = require('jquery');
require('slick-carousel');
require('./product/quickView');
require('./app');

var processInclude = require('core/util');
processInclude(require('./components/quickAccess'));

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./wishlist/wishlistItemsLoader'));
    processInclude(require('core/components/cookie'));
    processInclude(require('core/components/consentTracking'));
    processInclude(require('core/components/footer'));
    processInclude(require('core/components/miniCart'));
    processInclude(require('core/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('core/components/clientSideValidation'));
    processInclude(require('core/components/countrySelector'));
    processInclude(require('core/components/toolTip'));
    require('lazysizes');
    processInclude(require('./components/lazysizes'));
    processInclude(require('./components/carouselTile'));
    processInclude(require('./components/carouselDetail'));
    processInclude(require('./common'));
    processInclude(require('./utils/urlUtils'));
    processInclude(require('./utils/obfuscation'));
    processInclude(require('./global/gtm'));
    processInclude(require('./global/contentSquare'));
    processInclude(require('./components/einstein'));
});
require('jqueryui');
require('jquery-migrate');
jQuery.migrateMute = true;
require('core/thirdParty/bootstrap');
require('core/components/spinner');
require('jqueryui');
require('jquery-migrate');
jQuery.migrateMute = true;
